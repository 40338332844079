@import url('https://fonts.googleapis.com/css2?family=Electrolize&family=Teko:wght@300..700&display=swap');

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Electrolize", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#f1e4d2;
}

.background {
  pointer-events: none;
  z-index: -10;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  background: linear-gradient(150deg, rgba(171,103,0,1) 0%, rgba(177,143,69,1) 27%, rgb(168, 48, 19) 57%, rgba(96,30,16,1) 100%);

}

.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100vw;
  height:100vh;
}

.home-container {
  justify-content: center;
  align-items: center;
  width:40vw;
  height:100vh;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.home-container h1 {
  font-family: "Teko", sans-serif;
  font-size: 7rem;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #f1e4d2;
  margin-top: 3%;
  text-align: left;
  white-space: nowrap;
}

.home-container p {
  position: relative;
  color: #f1e4d2;
  margin-top: 30vh;
  text-align: left;
  font-size: 1.5em;
  font-family: "Electrolize", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.home-preview {
  position: absolute;
  display: flex;
  width:100%;
  height:25%;
  top: 20%;
  overflow: hidden;
}

.home-preview::before {
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Prevent interference with clicks */
}

.cover-image {
  width: 100%;
  height: auto;
  justify-self: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cover-title {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  justify-content: flex-start;
  align-self: flex-start;
  position: relative;
  border-style: none;

  width: 100%;

  text-transform: uppercase;
  color: #ffffff;
  z-index: 11;
  filter: drop-shadow(2px 2px 10px #0e0e0e5e);
}

.post-container {
  width:77vw;
  height:100vh;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.post-container::-webkit-scrollbar {
  width: 0.7vw;
}

/* Track */
.post-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.post-container::-webkit-scrollbar-thumb {
  background: rgba(116, 84, 72, 0.445); 
  border-radius: 10px;
}

/* Handle on hover */
.post-container::-webkit-scrollbar-thumb:hover {
  background: #5e7d80cb; 
}

.post-container h1 {
  font-size: 4rem;
  padding-left: 2vw;
}

.post-container h2 {
  font-size: 2rem;
  padding-left: 2vw;
}

.post-container p {
  font-size: 1.2rem;
  padding-left: 2vw;
}

#main {
  background: linear-gradient(90deg, rgba(84,51,39,0) 0%, rgba(19, 9, 2, 0.856) 40%, rgb(19, 9, 2) 80%);
  padding:0%;
  animation: fade 1s linear;
  z-index: 1;
}

@keyframes fade {
  0%{ opacity: 0 }
  100% { opacity: 1 }
}

@media only screen and (max-width: 920px) {
  .home-container {
    display: none;
  }
}